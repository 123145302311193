<template>
  <footer id="footer">
    <div class="quick_btn">
      <a v-show="isShowBtnTop" ref="btnTopRef" href="#" class="btn_top" @click.prevent="onScrollTop">
        <i class="icon_font_arrow_04" />
        <span class="blind">위로</span>
      </a>
      <a href="http://ound.channel.io" target="_blank" class="btn_talk">
        <span class="blind">kakaotalk</span>
      </a>
    </div>

    <div class="menu">
      <ul class="inner_box">
        <li><a :href="PATH.INFORMATION" target="_blank">서비스 소개<i class="icon_size_14 icon_font_go" /></a></li>
        <li><a :href="PATH.NOTICE" target="_blank">공지사항<i class="icon_size_14 icon_font_go" /></a></li>
        <li><a :href="PATH.FAQ" target="_blank">자주 묻는 질문<i class="icon_size_14 icon_font_go" /></a></li>
        <li><a :href="PATH.TERMS" target="_blank">이용약관<i class="icon_size_14 icon_font_go" /></a></li>
        <li><a :href="PATH.PRIVACY_POLICY" target="_blank">개인정보처리방침<i class="icon_size_14 icon_font_go" /></a></li>
      </ul>
    </div>
    <div class="inner_box address">
      <div class="logo">
        <span class="blind">OUND</span>
      </div>
      <address>
        <span>주식회사 프로파운드</span>
        <span>대표자 : 이유경</span>
        <span>개인정보보호책임자 : 이유경</span>
        <span>주소 : 서울특별시 영등포구 여의대로 108 파크원 타워1 5층</span>
        <span>연락처 : <a href="tel:070-8065-0183">070-8065-0183</a></span>
        <span>이메일 : <a href="mailto:help@ound.ai" target="_blank">help@ound.ai</a></span>
        <span>사업자등록번호 : 339-86-00601 (<a href="https://www.ftc.go.kr/bizCommPop.do?wrkr_no=3398600601" target="_blank" class="link_line">사업자 정보확인</a>)</span>
        <span>통신판매업신고번호 : 2024-서울영등포-0492호</span>
      </address>
      <div class="in_box copyright">
        <p>ⓒ Copyright all right reserved PROFOUND.</p>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
import PATH from '~/config/route'

// onScrollTop
const onScrollTop = () => {
  window.scrollTo({ top: 0, behavior: 'smooth' })
}

// btn_top 버튼이 어느정도 스크롤 되면 보이도록 처리
const btnTopRef = ref<HTMLElement | null>(null)
const isShowBtnTop = ref(false)
const handleScroll = () => {
  if (btnTopRef.value) {
    if (window.scrollY > 200) {
      isShowBtnTop.value = true
    } else {
      isShowBtnTop.value = false
    }
  }
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})

</script>

<style scoped>
  #footer .inner_box {position: relative; width:1200px; margin:0 auto; }
  #footer .menu{border-top:1px solid var(--color-line); border-bottom:1px solid var(--color-line); padding:1.25rem 0;}
  #footer .menu li{display:inline-block;margin:0 24px 0 0;padding:0.75rem 0;}
  #footer .menu li a{display: flex; align-items: center; gap:2px; font-size:var(--font-size-7); color:var(--color-text-footer-menu); line-height:100%; font-weight:800;}

  #footer .address .logo{display: block; width: 85.4px; height: 18px; background: url(@/assets/images/common/logo.svg) 0 0 no-repeat; margin:2rem 0 1.6875rem;}
  #footer .address address > span{font-size:var(--font-size-7); color:var(--color-text-supporting); line-height:165%;}
  #footer .address address > span:before{content:'|';display:inline-block; vertical-align:0; margin:0 13px 0 11px; color:var(--color-text-footer-bar);}
  #footer .address address > span a{text-decoration:none; color:var(--color-text-supporting);}
  #footer .address address > span .link_line{text-decoration:underline;}
  #footer .address address > span:nth-child(1):before,
  #footer .address address > span:nth-child(4):before,
  #footer .address address > span:nth-child(7):before{display:none}
  #footer .address address > span:nth-child(3):after,
  #footer .address address > span:nth-child(6):after{display:block;content:'';}
  #footer .copyright{font-size:var(--font-size-7); color:var(--color-text-supporting); line-height:160%; padding:10px 0 32px;}

  #footer .quick_btn{position:fixed; right:50%; bottom:60px; margin-right:-600px; text-align:right; display:flex; flex-direction: column; align-items: flex-end; z-index:1;}
  #footer .quick_btn .btn_top{display:flex; width:64px; height:64px; border:2px solid var(--color-icon-default);border-radius:100%; align-items: center; justify-content: center; background:var(--color-background-default); box-sizing:border-box}
  #footer .quick_btn .btn_top .icon_font_arrow_04:before{font-size:22px;}
  #footer .quick_btn .btn_talk{display:block; width:64px; height:64px; background: url(@/assets/images/common/ico_talk.svg) 0 0 no-repeat; margin-top:16px;}
  #footer.detail{padding-bottom:60px;}
  #footer.detail .quick_btn{bottom:84px;}
  #footer.detail .quick_btn .btn_talk, #footer.mypage .quick_btn .btn_talk{display:none;}
  #footer.mypage{padding-bottom:60px;}
  #footer.mypage .quick_btn{bottom:84px;}

  @media screen and (max-width: 1680px) {
      #footer .quick_btn{right:204px; margin:0;}
  }
  @media screen and (max-width: 1280px) {
      #footer .quick_btn{right:50%; margin-right:-396px;}
  }
  @media screen and (max-width: 832px) {
      #footer{padding-bottom:65px;}
      #footer .quick_btn{right:20px; margin:0;}
  }

  @media screen and (max-width: 845px) {
    #footer .address address > span:nth-child(5):before{display:none}
    #footer .address address > span:nth-child(4):after{display:block;content:'';}
  }

  @media screen and (max-width: 768px) {
    #footer .quick_btn{bottom:84px;}
    #footer .quick_btn .btn_top .icon_font_arrow_04:before{font-size:18.77px;}
    #footer .quick_btn .btn_top{ width:44px; height:44px;}
    #footer .quick_btn .btn_talk{width:44px; height:44px; background-size:44px auto;}
    #footer.detail{padding-bottom:40px;}
    #footer.detail .quick_btn{bottom:56px;}
    #footer.mypage{padding-bottom:40px;}
    #footer.mypage .quick_btn{bottom:56px;}
  }

  @media screen and (max-width: 560px) {
    #footer .address address > span:nth-child(1):after,
    #footer .address address > span:nth-child(3):after,
    #footer .address address > span:nth-child(5):after,
    #footer .address address > span:nth-child(6):after,
    #footer .address address > span:nth-child(7):after{display:block;content:'';}
    #footer .address address > span:nth-child(2):before,
    #footer .address address > span:nth-child(4):before,
    #footer .address address > span:nth-child(6):before,
    #footer .address address > span:nth-child(7):before,
    #footer .address address > span:nth-child(8):before{display:none}
  }
  @media screen and (max-width: 500px) {
    #footer .contact .box1 span:nth-child(3):before{margin:0 12px 0 8px;}
    #footer .contact .box2 strong{display:inline-block;margin-right:8px;}
    #footer .contact .box2 span{display:block;margin-right:0;}
    #footer .contact .box2 span br{display:none;}
    #footer .contact .box2 span a{display:inline-block;}
    #footer .contact .box2 span a:nth-child(4):before{content:'|';display:inline-block;vertical-align:0;margin-right:12px;margin-left:12px;}
  }

</style>
